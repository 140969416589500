<template>
  <div class="photos-modal">
    <div class="modal">
      <transition name="fade">
        <div class="loading" v-if="loading">
          <template v-if="id">Обновяване.</template>
          <template v-else>Качване.</template>
          Моля изчакайте...
        </div>
      </transition>
      <header>
        <h1 v-if="id">Редактиране на снимка</h1>
        <h1 v-else>Качване на снимки</h1>
        <span class="icon" @click="$emit('close')">L</span>
      </header>
      <div
        class="upload"
        :class="{ 'upload--highlighted': highlight }"
        @dragenter="highlight = true"
        @dragleave="highlight = false"
        @dragover.stop.prevent="highlight = true"
        @drop.stop.prevent="handleDrop($event)"
        v-if="!id"
      >
        <div class="texts">
          <p>1. Провлачете или изберете снимки за качване (една или повече)</p>
          <p>2. Изберете за кое изследване се отнасят</p>
          <p>3. Изберете зъб/зъби, ако изследването е за конкретен зъб</p>
        </div>
        <Button @click="toggleInput()">
          <span class="icon">W</span>
          <template v-if="files.length"
            >{{ files.length }} СНИМК{{
              files.length === 1 ? "А" : "И"
            }}</template
          >
          <template v-else>ИЗБЕРИ СНИМКИ</template>
        </Button>
        <input
          type="file"
          multiple
          ref="fileInput"
          @change="handleFiles($event.target.files)"
        />
      </div>
      <p>Вид изследване от направлението:</p>
      <div class="dropdown">
        <div class="dropdown-toggle" @click="dropdown = !dropdown">
          <p v-if="photo.category">{{ photo.category }}</p>
          <p v-else>Изберете вид изследване:</p>
          <span class="icon">p</span>
        </div>
        <div
          class="dropdown-items"
          :class="{ 'dropdown-items--active': dropdown }"
          @click="handleDropdownClick($event)"
        >
          <div>
            <p>Рентгенография на зъби</p>
            <!-- IF YOU CHANGE THIS MAKE SURE TO CHANGE IT ALSO IN Cornerstone.vue
            SOME CONTROLLS ON THE DICOM IMAGES ARE VISIBLE ONLY IF CATEGORY IS ONE OF THE NEXT TWO-->
            <div class="dropdown-item">Дигитална по Dieck</div>
            <div class="dropdown-item">Дигитална по паралелна техника</div>
            <!--  -->
            <p>Парадонтален статус</p>
            <div class="dropdown-item">Парадонтален статус</div>
            <p>Оклузални</p>
            <div class="dropdown-item">Коса по Belot (Горна челюст)</div>
            <div class="dropdown-item">Аксиална по Simpson (Долна челюст)</div>
            <p>Рентгенография на череп</p>
            <div class="dropdown-item">Профилна телерентгенография</div>
            <div class="dropdown-item">Лицева телерентгенография</div>
            <div class="dropdown-item">Околоносни кухини - дигитална</div>
            <div class="dropdown-item">Рентгенография по Хирц</div>
            <div class="dropdown-item">Рентгенография на череп - анфас</div>
            <div class="dropdown-item">Рентгенография на череп - профил</div>
            <p>Други центражи</p>
            <div class="dropdown-item">На шийни прешлени - дигитална</div>
            <div class="dropdown-item">На китка - дигитална</div>
            <div class="dropdown-item">Носни кости</div>
          </div>
          <div>
            <p>Ro gr за кариес диагностика /Bite-wing/</p>
            <!-- IF YOU CHANGE THIS MAKE SURE TO CHANGE IT ALSO IN Cornerstone.vue
            SOME CONTROLLS ON THE DICOM IMAGES ARE VISIBLE ONLY IF CATEGORY IS ONE OF THE NEXT TWO-->
            <div class="dropdown-item">/Bite-wing/ - L Left</div>
            <div class="dropdown-item">/Bite-wing/ - R Right</div>
            <!--  -->
            <p>Дигитална ортопантомография</p>
            <div class="dropdown-item">Дигитална ортопантомография</div>
            <div class="dropdown-item">Дигитална ортопантомография на ТМС</div>
            <div class="dropdown-item">Секторна - Iви сектор</div>
            <div class="dropdown-item">Секторна - IIри сектор</div>
            <div class="dropdown-item">Секторна - IIIти сектор</div>
            <p>Компютърна томография (СВСТ)</p>
            <div class="dropdown-item">Малък обем (4х4) в участъка</div>
            <div class="dropdown-item">Горна и долна челюстни половини - L</div>
            <div class="dropdown-item">Горна и долна челюстни половини - R</div>
            <div class="dropdown-item">Цяла горна челюст</div>
            <div class="dropdown-item">Цяла долна челюст</div>
            <div class="dropdown-item">Двете челюсти</div>
            <div class="dropdown-item">Квадрант I</div>
            <div class="dropdown-item">Квадрант II</div>
            <div class="dropdown-item">Квадрант III</div>
            <div class="dropdown-item">Квадрант IV</div>
          </div>
        </div>
      </div>
      <p>Изберете зъб/зъби, ако изследването е за конкретен зъб:</p>
      <div class="teeth-wrapper">
        <Teeth v-model="photo.teeth" />
      </div>
      <footer>
        <Button class="button green" @click="toggleInputDocs()">
          <span class="icon">W</span>
          <template v-if="docs.length"
            >{{ docs.length }} ФАЙЛ{{
              docs.length === 1 ? "" : "ОВЕ"
            }}</template
          >
          <template v-else>КАЧИ РАЗЧИТАНЕ</template>
          <!-- <template v-else>ИЗБЕРИ ФАЙЛОВЕ</template> -->
        </Button>
        <input
          type="file"
          ref="docsInput"
          @change="handleDocs($event.target.files)"
        />
        <Button
          @click="save()"
          :disabled="
            id ? false : (!photo.category || !files.length) && !docs.length
          "
        >
          <span class="icon">j</span>
          ЗАПИШИ
        </Button>
      </footer>
    </div>
  </div>
</template>

<script>
import Button from "@/components/Button";
import Teeth from "@/components/Teeth";
export default {
  components: {
    Button,
    Teeth,
  },
  props: ["id", "examination"],
  data() {
    return {
      loading: false,
      highlight: false,
      dropdown: false,
      files: [],
      docs: [],
      photo: {
        examination: this.examination,
        teeth: [],
        category: "",
      },
    };
  },
  methods: {
    toggleInput() {
      this.$refs.fileInput.click();
    },
    toggleInputDocs() {
      this.$refs.docsInput.click();
    },
    handleDrop(e) {
      this.highlight = false;
      const dt = e.dataTransfer;
      const files = dt.files;

      if (files.length) {
        this.handleFiles(files);
      }
    },
    validatePhoto(f) {
      if (f) {
        if (
          !f.name
            .toLowerCase()
            .match(/\.(dcm|dic|dicom|jpg|jpeg|bmp|ilumact|zip)$/)
        ) {
          this.$snotify.error(
            "Може да качвате само DICOM (.dcm, .dic, .dicom), JPG (.jpg, .jpeg), BMP, ILUMACT (.ilumact) или ZIP файлове."
          );
          return false;
        }

        return true;
      }
    },
    validateDoc(d) {
      if (d) {
        if (!d.name.toLowerCase().match(/\.(doc|docx)$/)) {
          this.$snotify.error(
            "Може да качвате само DOC (.doc, .docx) файлове."
          );
          return false;
        }

        return true;
      }
    },
    handleFiles(files) {
      this.files = [];
      for (let f of files) {
        if (this.validatePhoto(f)) {
          this.files.push(f);
        }
      }
    },
    handleDocs(docs) {
      this.docs = [];
      for (let d of docs) {
        if (this.validateDoc(d)) {
          this.docs.push(d);
        }
      }
    },
    handleDropdownClick(e) {
      if (e.target.classList.contains("dropdown-item")) {
        this.photo.category = e.target.innerHTML;
        this.dropdown = false;
      }
    },
    getPhoto() {
      this.$apiService
        .get(`/photos/${this.id}`)
        .then((res) => {
          if (res.data) {
            this.photo = res.data;
          } else {
            this.$emit("close", false);
          }
        })
        .catch((err) => {
          console.log(err);
          this.$snotify.error("Нещо се обърка");
        });
    },
    save() {
      // edit
      if (this.id) {
        this.loading = true;
        this.$apiService
          .put(`/photos/${this.id}`, this.photo)
          .then(() => {
            setTimeout(() => {
              this.$snotify.success("Снимката е редактирана успешно!");
              this.$emit("close", true);
            }, 1000);
          })
          .catch((err) => {
            this.$snotify.error(err.response.data.message);
            this.loading = false;
          });
      } else {
        // create
        this.loading = true;
        const fd = new FormData();

        fd.append("data", JSON.stringify(this.photo));

        for (let i = 0; i < this.files.length; i++) {
          fd.append("photos", this.files[i]);
        }
        for (let i = 0; i < this.docs.length; i++) {
          fd.append("docs", this.docs[i]);
        }

        this.$apiService
          .post("/photos", fd)
          .then(() => {
            setTimeout(() => {
              this.$snotify.success("Качването е успешно!");
              this.$emit("close", true);
            }, 1000);
          })
          .catch((err) => {
            this.$snotify.error(err.response.data.message);
            this.loading = false;
          });
      }
    },
  },
  mounted() {
    if (this.id) {
      this.getPhoto();
    }
  },
};
</script>

<style lang="scss" scoped>
@import "@/scss/variables.scss";
@import "@/scss/mixins.scss";
.photos-modal {
  align-items: center;
  display: flex;
  height: 100%;
  justify-content: center;
  left: 0;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 5;
}
.modal {
  background-color: #fff;
  box-shadow: 0px 0px 10px #00000047;
  // height: 60vh;
  padding: 20px;
  position: relative;
  width: 750px;
  header {
    align-items: center;
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
    @include noselect();
    h1 {
      color: #414141;
      font-size: 1.4rem;
    }
    .icon {
      cursor: pointer;
      font-size: 1.4rem;
      @include transition(all $transition $cubic);
      &:hover {
        opacity: 0.5;
      }
    }
  }
  > p {
    color: #414141;
    font-size: 1.1rem;
    margin-bottom: 5px;
  }
  .upload {
    align-items: center;
    background-color: #eaeaea;
    border: 1px dashed #c8c8c8;
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
    padding: 20px 15px;
    @include transition(all $transition $cubic);

    &.upload--highlighted {
      background-color: #d5e1f2;
      border: 1px solid #a3bde3;
    }
    .button {
      background-color: #6a6a6a;
      color: #fff;
      &:hover {
        background-color: #5c5c5c;
      }
    }
  }
  .dropdown {
    margin-bottom: 20px;
    // position: relative;
    .dropdown-toggle {
      align-items: center;
      background-color: #f3f3f3;
      border: 1px solid #c8c8c8;
      cursor: pointer;
      display: flex;
      justify-content: space-between;
      padding: 10px 15px;
      @include transition(all $transition $cubic);
      @include noselect();
      &:hover {
        background-color: rgba($color: #000, $alpha: 0.1);
      }
    }
    .dropdown-items {
      background-color: #f3f3f3;
      border: 1px solid #c8c8c8;
      display: flex;
      left: 20px;
      max-height: 90vh;
      overflow: auto;
      position: absolute;
      top: 50%;
      width: calc(100% - 40px);
      z-index: 1;
      @include scrollbar(5px, #a3bde3);
      @include transform(translateY(-50%) scaleY(0));
      @include transition(all $transition $cubic);
      > div {
        width: 50%;
        padding: 15px;
        margin-bottom: 10px;
      }
      p {
        color: #6a6a6a;
        font-weight: 700;
      }
      .dropdown-item {
        background-color: #d5e1f2;
        border: 1px solid #a3bde3;
        cursor: pointer;
        height: 28px;
        margin-top: 5px;
        padding: 5px;
        @include transition(all $transition $cubic);
        @include noselect();
        &:hover {
          background-color: #fff;
        }
        & + p {
          align-items: flex-end;
          display: flex;
          height: 28px;
          margin-top: 5px;
        }
      }
      &.dropdown-items--active {
        @include transform(translateY(-50%) scaleY(1));
      }
    }
  }
  .teeth-wrapper {
    border: 1px solid #c8c8c8;
    margin-bottom: 20px;
    padding: 10px 5%;
  }
  footer {
    display: flex;
    justify-content: space-between;

    .button {
      &:last-child {
        background-color: #2a5584;
        color: #fff;
        &:hover {
          background-color: rgb(35, 72, 110);
        }
      }
    }
  }
  input[type="file"] {
    height: 0.1px;
    opacity: 0;
    overflow: hidden;
    position: absolute;
    width: 0.1px;
    z-index: -1;
  }
}
.loading {
  align-items: center;
  background-color: rgba($color: #fff, $alpha: 0.8);
  display: flex;
  height: 100%;
  justify-content: center;
  left: 0;
  position: absolute;
  text-align: center;
  top: 0;
  width: 100%;
  z-index: 1;
}

@media screen and (max-width: $l) {
  .modal {
    max-height: 90vh;
    overflow: auto;
    width: 50vw;
  }
  .modal .dropdown .dropdown-items {
    position: fixed;
    left: 50%;
    width: calc(50vw - 40px);
    @include transform(translateX(-50%) translateY(-50%) scaleY(0));

    &.dropdown-items--active {
      @include transform(translateX(-50%) translateY(-50%) scaleY(1));
    }
  }
}
@media screen and (max-width: $sm) {
  .modal {
    height: initial;
    max-height: 90vh;
    width: 90vw;
  }
  .modal .dropdown .dropdown-items {
    width: calc(90vw - 40px);
  }
}
@media screen and (max-width: $s) {
  .upload {
    flex-direction: column;
    .texts {
      margin-bottom: 10px;
    }
  }
  .modal .dropdown .dropdown-items {
    flex-direction: column;
    > div {
      width: 100%;
    }
    .dropdown-item {
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
    }
  }
}
</style>