<template>
  <div class="photo-modal-wrapper">
    <div class="photo-modal">
      <Cornerstone :path="path" :category="category" :advanced="true" @close="$emit('close')" />
    </div>
  </div>
</template>
<script>
import Cornerstone from '@/components/Cornerstone.vue';
export default {
  props: ['path', 'category'],
  components: {
    Cornerstone
  }
};
</script>

<style lang="scss" scoped>
@import '@/scss/variables.scss';
.photo-modal-wrapper {
  align-items: center;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.19);
  display: flex;
  justify-content: center;
  left: 0;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 10;
}
.photo-modal {
  box-shadow: 10px 10px 204px -19px rgba(0, 0, 0, 0.12);
}

@media screen and (max-width: $s) {
  .photo-modal {
    width: 95%;
    height: 90vh;
  }
}
</style>