<template>
  <div class="teeth" :class="{ 'teeth--disabled': disabled }">
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="618.512"
      height="243.332"
      viewBox="0 0 618.512 243.332"
    >
      <g transform="translate(181.303 21273.496)" @click="clicked($event)">
        <path
          data-name="46"
          class="tooth"
          d="M.07,75.59c-.4-3.055,1-5.7,2.033-8.428A70.131,70.131,0,0,0,6.608,45.8a115.88,115.88,0,0,0-.547-20.6,5.58,5.58,0,0,0,5.72,1.179,5.279,5.279,0,0,1,2.441.049,14.3,14.3,0,0,0,8.752-1.416,8.229,8.229,0,0,1,5.585-.585,18.217,18.217,0,0,0,8.94-.084c1.5-.43,2.235-.306,1.863,1.668a43.06,43.06,0,0,0,.355,17.136c.675,3.164-.106,6.423-.5,9.616C38.44,59,36.63,64.858,32.639,69.884a26.615,26.615,0,0,1-4.625,4.543c-1.683,1.3-2.333.993-2.993-.963-1.084-3.212.393-5.859,1.742-8.516a19.509,19.509,0,0,0,.894-15.665c-.417-1.237-1.05-2.618-2.66-2.731-1.812-.126-2.685,1.261-3.338,2.6-2.417,4.969-5.016,9.838-7.59,14.725-2.723,5.173-6.153,9.649-11.082,12.9a2.989,2.989,0,0,1-1.55.6C.7,77.378.229,76.8.07,75.59ZM5.358,21.462a14.88,14.88,0,0,1-1.112-2.854c-.424-2.488-1.1-4.96-.826-7.536.2-2.025-.374-4.19,1.352-5.935a4.256,4.256,0,0,1,4.936-.864,2.556,2.556,0,0,0,3.018-.6c4.8-3.709,9.745-3.836,14.62-.191a1.235,1.235,0,0,0,1.85.005A17.184,17.184,0,0,1,36.04.12c3.628-.678,6.694,1.585,7.649,5.508,1.444,5.934-1.015,11.069-3.858,16.042-.511.894-1.519.759-2.353,1a17.926,17.926,0,0,1-8.935.077,8.436,8.436,0,0,0-5.778.6,13.636,13.636,0,0,1-8.732,1.4A6.533,6.533,0,0,0,11.4,24.8a7.389,7.389,0,0,1-1.55.179C7.735,24.981,6.3,23.824,5.358,21.462Z"
          transform="translate(-79.763 -21125)"
          :class="{ 'tooth--active': isActive(46)}"
        />
        <path
          data-name="36"
          class="tooth"
          d="M41.16,76.865c-5-3.269-8.437-7.825-11.2-13.048-2.579-4.879-5.158-9.756-7.594-14.709-.639-1.3-1.5-2.673-3.168-2.511-1.734.168-2.394,1.506-3.01,3.129-1.767,4.655-1.057,9.144.344,13.653a6.715,6.715,0,0,0,.974,1.517,50.047,50.047,0,0,1,2.139,6.525c.387,1.651-.628,2.588-1.271,3.752a.884.884,0,0,1-1.413.276c-3.7-2.918-7.01-6.163-8.718-10.706-3.229-6.532-4.076-13.591-3.917-20.7C4.408,40.479,5.476,36.921,5.4,33.26a42.013,42.013,0,0,0-.73-7.759c-.188-.9-.134-1.562,1.026-1.242a22.6,22.6,0,0,0,11.172,0c2.192-.493,3.937.81,5.837,1.444a15.014,15.014,0,0,0,7.478.714,4.206,4.206,0,0,1,1.829-.036,6.127,6.127,0,0,0,5.966-1.022,123.22,123.22,0,0,0-.485,20.17,69.743,69.743,0,0,0,3.244,18.159c.969,3.107,2.618,5.97,3.176,9.213a8.213,8.213,0,0,1,.084,2.862c-.2,1.111-.675,1.654-1.381,1.654A2.8,2.8,0,0,1,41.16,76.865ZM32.675,24.834a5.715,5.715,0,0,0-2.432-.112,13.344,13.344,0,0,1-8.923-1.346,7.955,7.955,0,0,0-5.576-.634A20.933,20.933,0,0,1,5.6,22.552,2,2,0,0,1,4.258,21.7C1.461,16.8-.845,11.779.3,5.865A6.953,6.953,0,0,1,10.229.7c5.128,2.353,5.17,2.478,10.465.755,3.925-1.277,7.264-.25,10.379,2,1.128.814,1.894,1.358,3.494.781,3.259-1.172,5.8.769,6.019,4.217.061.954.009,1.916.009,2.875A24.17,24.17,0,0,1,38.75,21.51c-1.075,2.355-2.413,3.505-4.505,3.505A7.542,7.542,0,0,1,32.675,24.834Z"
          transform="translate(292.15 -21125)"
          :class="{ 'tooth--active': isActive(36)}"
        />
        <path
          data-name="26"
          class="tooth"
          d="M.058,65.543C.742,61.351.763,56.97,3.62,53.4s6.859-4.983,11-3.289a9.943,9.943,0,0,0,5.723.254,9.232,9.232,0,0,1,6.248.748c.889.5,1.927-.043,2.931.547a8.668,8.668,0,0,1,4.26,5.162c.918,3,2.048,5.944,2.1,9.125.073,4.121-1.71,6.25-5.648,7.635-2.82.99-5.448,1.672-8.22-.075a7.151,7.151,0,0,1-2.14-1.759c-1.214-1.748-2.31-1.49-3.766-.285A12.476,12.476,0,0,1,9.39,74.621c-.243.022-.49.033-.739.033C4.433,74.654-.6,71.54.058,65.543ZM3.2,51.045l-.313-.322a195.546,195.546,0,0,0-.174-21.558A29.124,29.124,0,0,1,7.5,11.113c1.674-2.5,4.153-4.018,6.478-5.718,1.1-.806,1.992-.142,2.178,1.332.386,3.038-1.053,5.731-1.565,8.6-1.287,7.209-1.259,14.235,2.653,20.813.683,1.15.775,1.229,1.462.063a115.136,115.136,0,0,0,8.656-18.5c1.273-3.433,2.489-6.887,3.731-10.331A1.238,1.238,0,0,1,31.256,7c.834-.994,1.254-2.887,2.791-2.536,1.3.3,1.623,1.926,1.914,3.313,1.174,5.592.3,11.053-1.022,16.454-2.052,8.393-3.858,16.8-3.045,25.521.109,1.17-.269,1.512-1.3.936A15.7,15.7,0,0,0,20.04,48.737a11.483,11.483,0,0,1-6.151-.523c-3.272-1.214-6.243-.07-9,1.8-.527.357-.876,1.031-1.625,1.031ZM17.9,34.52c-.637-.023-.383-.59-.53-.9-2.432-5.21-2.686-10.6-1.813-16.243.971-6.29,4.017-11.4,8.009-16.12A2.628,2.628,0,0,1,25.986.032c1.139.206,1.148,1.308,1.421,2.243.613,2.084.541,4.2.593,7.141.555,3.929-1.834,7.924-3.455,12.065A85.416,85.416,0,0,1,18.67,33.728c-.2.339-.212.792-.729.792Z"
          transform="translate(292.501 -21253)"
          :class="{ 'tooth--active': isActive(26)}"
        />
        <path
          data-name="16"
          class="tooth"
          d="M22.569,72.957a2.331,2.331,0,0,0-.357-.2c-1.351-.619-2.281-2.23-3.732-2.272-1.466-.042-1.955,1.858-3.152,2.595-2.162,1.328-4.375,2.15-6.959,1.255C5.756,73.425,2.98,72.951,1.5,70.092a9.256,9.256,0,0,1-.689-6.38A49.566,49.566,0,0,1,3.2,55.481c1.273-3.154,4.194-4.262,7.317-4.878a11.572,11.572,0,0,1,6.052-.048,8.1,8.1,0,0,0,5.887-.638c4.291-1.832,11.2,1.494,12.55,7.672.579,2.644,1.731,5.234,1.313,8.036a7.382,7.382,0,0,1-3.458,7.687,9.445,9.445,0,0,1-4.907,1.458A9.33,9.33,0,0,1,22.569,72.957Zm5.08-24.809a10.346,10.346,0,0,0-6.4.426,5.814,5.814,0,0,1-3.369.364,18.837,18.837,0,0,0-12.3,1.674c-.851.431-1.1.209-1-.712.725-6.381-.251-12.645-1.549-18.844-.668-3.19-1.69-6.308-2.164-9.551C.182,16.76-.581,12.012.661,7.248c.286-1.1.631-2.5,1.769-2.788,1.33-.342,2.005,1.089,2.418,2.034,1.166,2.662,2.1,5.427,3.093,8.162a133.719,133.719,0,0,0,9.907,21.722c.482.827.728,1.07,1.29.04a26.328,26.328,0,0,0,3.448-15.783,38.508,38.508,0,0,0-2.044-10.2.56.56,0,0,1-.059-.2c-.008-1.661-.951-3.769.57-4.82,1.471-1.016,2.632.942,3.805,1.728,5.364,3.593,7.251,9.152,8.431,15.132A36.848,36.848,0,0,1,33.614,32.7a125.362,125.362,0,0,0,0,18.061l-.313.319C31.359,50.235,29.844,48.67,27.649,48.148ZM17.6,33.276A122.92,122.92,0,0,1,9.023,13.808c-.608-1.726-.347-3.524-.484-4.97A23.968,23.968,0,0,1,9.2,1.8c.548-1.991,1.845-2.4,3.4-.888A28.135,28.135,0,0,1,17.82,8.366c3.544,6.909,4.708,14.114,2.612,21.731-.3,1.076-.2,2.264-1.087,3.113-.4.382,0,1.213-.677,1.325a1.107,1.107,0,0,1-.179.016C17.774,34.551,17.835,33.7,17.6,33.276Z"
          transform="translate(-72.691 -21253)"
          :class="{ 'tooth--active': isActive(16)}"
        />
        <path
          data-name="47"
          class="tooth"
          d="M16.786,68.979c-.184-2.307,1.034-4.207,1.709-6.255a100.972,100.972,0,0,0,4-16.478c.181-1.189-.154-2.152-1.147-2.506s-1.757.031-2.506,1.186c-3.459,5.344-6.171,11.11-9.553,16.487A30.469,30.469,0,0,1,2.9,68.966c-1.407,1.15-2.063.952-2.57-.831A10.331,10.331,0,0,1,1.348,60.6c3.528-7.129,5.838-14.56,5.435-22.586a151.081,151.081,0,0,0-2.216-17.5l.388-.328a36.191,36.191,0,0,0,3.556,2.592,11.536,11.536,0,0,0,10.063.637,15.31,15.31,0,0,1,8.558-.5,12.267,12.267,0,0,0,9.194-1.681l.245.35c-.006,3.931-1.162,7.686-1.81,11.516A146.627,146.627,0,0,1,31.546,48.1c-1.8,6.448-3.145,13.108-7.408,18.565a10.705,10.705,0,0,1-5.225,3.918,3.317,3.317,0,0,1-.971.183C17.056,70.77,16.874,70.079,16.786,68.979ZM3.5,13.6c-.761-3.379.472-9.545,4.425-11.48A7.941,7.941,0,0,1,13.5,1.593,14.758,14.758,0,0,1,18.78,3.71c2.049,1.5,3.992,1.147,6.036-.254A20.2,20.2,0,0,1,29.691.533c6.018-2.065,9.207,2.231,9.291,6.781.015.822,0,1.644,0,2.465-.033,1.9.14,3.81-.6,5.668-1.713,4.294-4.437,6.227-9.09,6.043a21.228,21.228,0,0,1-5.249-.737c-1.773-.528-3.33.292-4.927.864a14.387,14.387,0,0,1-4.847.886C8.924,22.5,4.731,19.059,3.5,13.6Z"
          transform="translate(-124.231 -21125)"
          :class="{ 'tooth--active': isActive(47)}"
        />
        <path
          id="_27"
          data-name="27"
          class="tooth"
          d="M4.638,71.982A8.109,8.109,0,0,1,.078,63.326c-.107-4.85,1.362-8.671,4.963-11.568,2.286-1.84,5.018-2.066,7.756-2.111a33.089,33.089,0,0,0,4.858-.71c4.655-.767,9.39-.354,12.321,3.547,4.6,6.125,4.736,13.791-3.679,17.522-2.034.9-3.9.35-5.674-.667A3.816,3.816,0,0,0,15.575,70a9.136,9.136,0,0,1-6.569,3.15A9.418,9.418,0,0,1,4.638,71.982ZM2.785,50.652c-.483-.287-.213-.856-.247-1.313C2.22,44.912,4.108,40.733,3.9,36.292A46.21,46.21,0,0,1,5.162,23.027,41.6,41.6,0,0,1,13.157,7.652a13.026,13.026,0,0,1,1.919-2.293c.675-.539,1.316-1.667,2.412-1.077a2.164,2.164,0,0,1,.82,2.562,34.787,34.787,0,0,1-1.169,4.34A44.615,44.615,0,0,0,14.8,33.254c.01.068.011.137.023.2.224,1.255-.468,3.176.875,3.543.789.216,1.6-1.819,2.264-2.922,1.741-2.913,2.408-6.211,3.31-9.439,1.589-5.675,1.084-11.495,1.451-17.254.063-1,.176-1.913,1.364-2.22,1.565-.4,2.412.673,3.1,1.68,3.452,5.022,3.918,10.757,3.954,16.636.04,6.609-1.485,13.069-1.661,19.65-.054,2.032-.2,4.062-.314,6.371a14.553,14.553,0,0,0-11.8-2.21,31.784,31.784,0,0,1-6.528.719,9.99,9.99,0,0,0-6.6,2.273,1.7,1.7,0,0,1-1.007.5A.878.878,0,0,1,2.785,50.652ZM18.268,11.637c.764-2.232,1.741-4.456,1.117-6.967C19.148,3.717,21.474.4,22.4.1c1.362-.443,1.66.734,2,1.527.315.734,1.184,1.79-.373,2.3-2.138.7-2.515,2.277-2.562,4.34-.163,7.053-.245,14.137-2.531,20.939A20.156,20.156,0,0,1,16.2,34.51,46.469,46.469,0,0,1,18.268,11.637Z"
          transform="translate(343.173 -21251)"
          :class="{ 'tooth--active': isActive(27)}"
        />
        <path
          data-name="17"
          class="tooth"
          d="M15.674,68.837C10.241,69.632,5.36,72,1.789,66.325-9.078,43.731,33.116,43.86,32.841,60.946c.977,7.9-3.032,11.773-8.087,11.773A13.946,13.946,0,0,1,15.674,68.837Zm4.163-21.005a65.989,65.989,0,0,1-6.7-.883A13.674,13.674,0,0,0,3.87,49.456c-.112-2.194-.15-4.211-.328-6.216-.577-6.525-1.782-13-1.614-19.584.085-3.319-.228-6.683.71-9.926C3.428,11,4.3,8.278,6.188,6.033a2.087,2.087,0,0,1,2.5-.875,2.63,2.63,0,0,1,1.64,2.572c.182,5.059.062,10.139.931,15.156.912,5.267,2.5,10.259,6.123,14.792,1.186-2.209.894-4.408,1.1-6.437a46.4,46.4,0,0,0-2.429-19.8,44.309,44.309,0,0,1-1.33-4.707,2.062,2.062,0,0,1,.905-2.515c1.012-.485,1.762.473,2.24,1.033a59.1,59.1,0,0,1,7.725,11.538A34.993,34.993,0,0,1,28.962,32.1c.155,6.459,1.449,12.79,1.606,19.227a4.488,4.488,0,0,1-.118.628C27.488,48.911,23.912,47.841,19.837,47.831ZM13.258,26.459c-1.6-6.137-1.577-12.452-1.674-18.731-.031-1.95-.97-3.288-2.519-3.6-1.783-.365-1.013-1.1-.723-1.89.325-.878.586-1.955,1.6-2.192,1.145-.268,1.583.832,2.182,1.552a5.131,5.131,0,0,1,1.388,4.4c-.314,1.973.636,3.865,1.276,5.728a46.578,46.578,0,0,1,2.037,22.918A24.126,24.126,0,0,1,13.258,26.459Z"
          transform="translate(-119.887 -21251)"
          :class="{ 'tooth--active': isActive(17)}"
        />
        <path
          data-name="38"
          class="tooth"
          d="M25.006,62.468C17.2,58.332,11.971,51.831,8.559,43.852c-3.115-7.279-4.235-15.043-5-22.87C3.4,19.337,3.832,18.7,5.569,18.6a30.75,30.75,0,0,1,12.947,2.059,19.353,19.353,0,0,0,16.527-.929,56.623,56.623,0,0,0-.614,13.6c.608,8.54,2.826,16.477,9.545,22.459.5.447,1.005.895,1.475,1.373.661.674,1.649,1.422,1.147,2.393-.42.811-1.594.853-2.531.878-4.043.107-7.034-2.009-9.806-4.631A52.8,52.8,0,0,1,22.566,39.224c-.337-.732-.46-2.056-1.847-1.6s-.739,1.625-.622,2.453a47.309,47.309,0,0,0,7.258,19.651c.778,1.184,1.559,2.757.679,3.74a.836.836,0,0,1-.645.305C26.64,63.774,25.746,62.861,25.006,62.468Zm-5.98-43.33C14.471,17.261,9.849,16.29,5.1,17.164c-1.978.365-2.368-.606-2.624-1.635-.3-1.185-.869-2.256-1.13-3.407a43.98,43.98,0,0,1-1.3-5.028A6.976,6.976,0,0,1,.186,4.668,3.876,3.876,0,0,1,1.547,2.844,15.889,15.889,0,0,1,5.288.39,6.505,6.505,0,0,1,9.445.3a19.152,19.152,0,0,1,3.314,1.01c2.045.814,4.064,1.247,6.283.28a10.587,10.587,0,0,1,8.211-.045c2.077.794,4.08,1.748,6.443,1.041.633-.189,1.328.413,1.856,1.056A7.792,7.792,0,0,1,37.1,8.823c-.032,5.771-.313,8.349-5.084,10.439a15.306,15.306,0,0,1-6.169,1.271A17.97,17.97,0,0,1,19.025,19.139Z"
          transform="translate(390 -21125)"
          :class="{ 'tooth--active': isActive(38)}"
        />
        <path
          data-name="48"
          class="tooth"
          d="M18.6,63.561c-.464-.311-.134-.873-.127-1.334.04-2.319,1.706-3.858,2.72-5.7a44.791,44.791,0,0,0,3.747-9.366c.729-2.545,1.215-5.1,1.748-7.67.146-.7.375-1.525-.664-1.864-1.139-.37-1.343.52-1.672,1.222A52.891,52.891,0,0,1,11.273,56.875c-2.6,2.253-5.449,3.78-9,3.543-.79-.053-1.823-.113-2.155-.807-.426-.89.363-1.592,1.007-2.254a52.724,52.724,0,0,0,5.35-5.75c4.034-5.59,5.406-11.976,5.821-18.628a50.685,50.685,0,0,0-.388-11.489,9.454,9.454,0,0,1-.013-1.562c6.344,3,12.442,2.363,18.781-.29,3.671-1.537,7.846-1.437,11.859-.721,1.1.2.658,1.115.582,1.661-.588,4.231-.815,8.512-1.735,12.695a54.254,54.254,0,0,1-6.793,17.179A35.774,35.774,0,0,1,20.147,63.393a2.346,2.346,0,0,1-1.016.325A.923.923,0,0,1,18.6,63.561ZM12.664,18.137c-1.989-1.779-3.177-4.953-3.132-8.64.308-1.8.14-3.955,1.585-5.763.573-.719,1.29-1.347,2.011-1.157,2.213.587,4.064-.154,6.053-.953a10.57,10.57,0,0,1,8.415-.052c2.346,1.042,4.526.579,6.67-.4A15.177,15.177,0,0,1,40.053.009C44.74-.185,46.725,2.7,46.342,7.2c-.278,3.26-1.548,6.351-2.779,9.4a1.232,1.232,0,0,1-1.394.812,27.685,27.685,0,0,0-9.973.205,17.82,17.82,0,0,0-5.206,1.894,11.792,11.792,0,0,1-4.856.773C18.487,20.287,14.177,19.489,12.664,18.137Z"
          transform="translate(-180.827 -21125)"
          :class="{ 'tooth--active': isActive(48)}"
        />
        <path
          data-name="23"
          class="tooth"
          d="M1.728,88.53A13.65,13.65,0,0,1,.455,78.238c1.222-5.387,2.371-10.868,6.158-15.1,4.894-5.462,8.943-5.237,13.4-.421A17.007,17.007,0,0,1,23.646,69.7a7.8,7.8,0,0,1,.215.785c.715,4.02,1.79,7.984,1.931,12.582.187,2.645-1.033,4.93-3.755,6.62-2.634,1.635-5.045,3.866-7.951,4.782a11.371,11.371,0,0,1-3.414.573C7.01,95.041,4.21,92.788,1.728,88.53ZM4.7,58.489c.814-7.278,1.58-14.563,2.548-21.821,1.164-8.735,2.6-17.435,5.654-25.75A23.778,23.778,0,0,1,19.277.969C21.047-.59,21.994-.29,22.415,2.011A6.721,6.721,0,0,1,22.48,4.46c-1.921,9.707-1.526,19.5-1.261,29.3.246,9.051.536,18.1.957,27.143.023.506.23,1.094-.31,1.507-6.526-6.871-9.377-6.739-17.5.889C3.909,61.6,4.524,60.051,4.7,58.489Z"
          transform="translate(198.283 -21272.998)"
          :class="{ 'tooth--active': isActive(23)}"
        />
        <path
          data-name="28"
          class="tooth"
          d="M.014,55.939c-.268-6.8,3.38-11.429,9.04-14.708C16.021,37.2,23.163,40.1,28.011,44.374A8.113,8.113,0,0,1,30.833,51.2c.041,4.718-1.615,8-5.1,10.319a4.227,4.227,0,0,1-5.837-.654,4.376,4.376,0,0,0-6.2-.458,23.593,23.593,0,0,1-3.643,2.1,7.329,7.329,0,0,1-3.33.855C2.967,63.361.173,59.953.014,55.939ZM4.666,23.646C6.3,17.281,8.984,11.674,13.673,7.061c.292-.285.619-.532.928-.8,1.156-1,1.551-.3,1.595.79a9.337,9.337,0,0,1-.717,3.56,30.64,30.64,0,0,0-1.315,17.747,3.743,3.743,0,0,0,.366,1.167c.22.344-.016,1.1.718,1,.7-.094.708-.652.753-1.243A41.2,41.2,0,0,1,19.66,14.3c1.8-3.748,3.829-7.277,7.261-9.822.824-.611,1.692-1.526,2.617-1.061,1.156.582.845,1.967.562,2.989a76.364,76.364,0,0,0-2.6,12.4A72.456,72.456,0,0,0,28.046,39.9c.125.665.211,1.337.41,2.619-9.55-7.069-18.116-6.359-26.132,2.13A84.151,84.151,0,0,1,4.666,23.646ZM22.293,2.272a4.167,4.167,0,0,1,1.059-.939c.834-.434,1.7-2,2.5-1.017a2.887,2.887,0,0,1-.536,3.723c-4.691,4.251-7.192,9.73-9.019,15.6-.5,1.594-.723,3.272-1.073,4.912A26.74,26.74,0,0,1,22.293,2.272Z"
          transform="translate(390.264 -21241)"
          :class="{ 'tooth--active': isActive(28)}"
        />
        <path
          data-name="18"
          class="tooth"
          d="M22.677,63.139a15.246,15.246,0,0,1-5.58-2.753,4.584,4.584,0,0,0-5.683-.14c-3.42,2.916-6.2,2.646-8.9-.969A10.7,10.7,0,0,1,.046,51.682c-.422-5.267,2.9-8.113,6.96-10.3,9.539-5.152,18.359.086,22.119,7.157,2.474,4.652,2.774,9.037-.713,12.9a5.451,5.451,0,0,1-4.1,1.947A5.87,5.87,0,0,1,22.677,63.139ZM2.477,42.424c.322-2.539.66-4.675.853-6.824.589-6.529.8-13-.564-19.535C2.079,12.779,1.267,9.5.679,6.192.5,5.2.212,3.939,1.291,3.391c.887-.45,1.74.36,2.472.949,5.544,4.453,8.29,10.509,9.865,17.233a32.926,32.926,0,0,1,1.19,7.864c-.005.494.01.971.67,1.066.849.123.537-.54.668-.93,2.268-6.732,1.327-13.26-1.1-19.747-.446-1.189-1.175-3.015.059-3.78,1.14-.707,2.023,1.014,2.834,1.832,4.813,4.855,7.323,10.881,8.606,17.478a93.416,93.416,0,0,1,1.961,19.293C20.469,36.14,11.908,35.445,2.477,42.424ZM13.7,17.045C11.891,12.392,9.676,8.053,6.056,4.534c-.383-.372-.99-.748-1.042-1.176C4.887,2.312,4.052.973,5,.242,6.016-.544,6.85.788,7.538,1.388,11.8,5.1,14,10,15.456,15.339c.839,3.081.544,6.167.308,9.3C14.711,22.181,14.673,19.529,13.7,17.045Z"
          transform="translate(-164.781 -21241)"
          :class="{ 'tooth--active': isActive(18)}"
        />
        <path
          data-name="13"
          class="tooth"
          d="M10.347,93.8c-2.213-1.416-4.385-2.9-6.663-4.2C.8,87.947-.281,85.484.061,80.8c.76-4.543.994-10.752,4.25-16.206A13.267,13.267,0,0,1,8.6,60.115c2.87-1.808,5.96-1.232,8.95,1.375,3.519,3.068,5.2,7.161,6.417,11.486.609,2.161,1.007,4.39,1.51,6.575,1.3,5.63-2.248,12.02-6.744,14.442a8.687,8.687,0,0,1-4.114,1.084A7.848,7.848,0,0,1,10.347,93.8ZM12.8,57.456C8.832,57.092,6.282,59.572,3.86,62.5c-.622-1.021-.321-2.053-.274-3q.942-18.9,1.149-37.827A97.828,97.828,0,0,0,3.274,4.728C3.009,3.1,2.726,1,4.218.208c1.6-.855,2.644,1.131,3.637,2.19,3.614,3.855,5.212,8.726,6.6,13.673,2.8,10,4.066,20.264,5.287,30.536.653,5.487,1.25,10.981,1.872,16.473l-.508.306C18.861,60.631,16.6,57.8,12.8,57.456Z"
          transform="translate(32.321 -21273)"
          :class="{ 'tooth--active': isActive(13)}"
        />
        <path
          data-name="43"
          class="tooth"
          d="M4.188,92c-.541-.355-.243-1-.165-1.525A154.334,154.334,0,0,0,5.31,61.053C4.953,52.915,4.161,44.8,3.194,36.706a51.985,51.985,0,0,1-.323-5.7c2.466,3.064,4.938,5.439,8.753,5.109,3.557-.308,6.153-2.414,8.359-5.559.287,2.456-.178,4.45-.252,6.457a246.487,246.487,0,0,1-2.189,25.544c-1.105,7.786-2.317,15.628-6.087,22.726A15.085,15.085,0,0,1,5.7,91.619a2.593,2.593,0,0,1-1.127.5A.687.687,0,0,1,4.188,92Zm.155-61.6A24.535,24.535,0,0,1,.35,12.373C.68,10.394,1.965,8.927,3.119,7.5A30.746,30.746,0,0,1,11.174.464,2.122,2.122,0,0,1,13.463.247c3.285,1.789,6.433,3.74,8.4,7.1,1.287,2.2,1.459,4.642,1.639,8.047-.661,5.156-2.225,10.99-6.058,15.866a7.908,7.908,0,0,1-6.2,3.281A8.374,8.374,0,0,1,4.343,30.4Z"
          transform="translate(39.492 -21125)"
          :class="{ 'tooth--active': isActive(43)}"
        />
        <path
          data-name="21"
          class="tooth"
          d="M.1,78.03c.513-4.4,1.079-8.809,2.021-13.159.72-3.329,1.244-6.692,2.8-9.784a16.369,16.369,0,0,1,6.646-6.98c2.417-1.372,6.88,0,9.427,2.622a18.2,18.2,0,0,1,4.931,10.9c.338,2.856.615,5.719,1,8.569a17.031,17.031,0,0,1-1.048,8.672,5.151,5.151,0,0,1-4.886,3.451c-3.014.087-6.033.022-9.05.022v.166c-2.537,0-5.073-.013-7.609,0H4.172C.613,82.512-.332,81.695.1,78.03ZM5.807,39.161A175.513,175.513,0,0,1,8.719,20.336c1.417-6.386,3.213-12.693,7.534-17.864.937-1.121,1.868-3,3.649-2.327,1.757.667,2.329,2.525,2.3,4.4-.026,2.123-.037,4.252-.175,6.37A198.642,198.642,0,0,0,23.256,46.4c.169,1.48.169,2.978.27,4.872-2.745-3.113-5.493-5.5-9.657-5.229-4.006.261-6.356,3.044-9.179,6.147C4.838,47.418,5.351,43.3,5.807,39.161Z"
          transform="translate(137.476 -21260.002)"
          :class="{ 'tooth--active': isActive(21)}"
        />
        <path
          data-name="11"
          class="tooth"
          d="M14.927,82.542v-.113c-2.674,0-5.348.024-8.022-.005-3.2-.037-4.937-1.374-6.021-4.386C-.851,73.219.429,68.4.859,63.6c.366-4.113,1.314-8.073,3.994-11.385,2.3-2.848,5-4.915,8.988-4.373,2.411.326,4.045,1.886,5.614,3.48,3.1,3.146,4.276,7.222,5.079,11.387.864,4.492,1.483,9.03,2.276,13.536A13.927,13.927,0,0,1,27,79.716c-.127,1.695-.976,2.808-2.817,2.82q-1.935.013-3.871.011C18.516,82.547,16.722,82.542,14.927,82.542ZM3.742,50.893c-.473-3.851.573-7.47.8-11.139.565-9.3,1.227-18.6.584-27.929-.169-2.459-.14-4.931-.293-7.391A5.485,5.485,0,0,1,6.147.862C7.175-.564,8.484-.009,9.443,1a29.287,29.287,0,0,1,6.5,10.92c3.434,10.174,4.538,20.765,5.735,31.354.323,2.857.44,5.684.519,8.986C16.388,44.6,10.216,43.965,3.742,50.893Z"
          transform="translate(91.987 -21260)"
          :class="{ 'tooth--active': isActive(11)}"
        />
        <path
          data-name="33"
          class="tooth"
          d="M17.826,93.364a23.766,23.766,0,0,1-6.377-9.949C8.391,75.1,6.96,66.4,5.8,57.667c-.968-7.259-1.734-14.543-2.547-21.821-.175-1.562-.79-3.115-.337-4.812,8.128,7.628,10.979,7.76,17.5.889.54.413.334,1,.311,1.507-.42,9.044-.711,18.092-.957,27.143-.265,9.806-.661,19.6,1.26,29.3a6.721,6.721,0,0,1-.066,2.449c-.247,1.351-.675,2.012-1.345,2.011A3.1,3.1,0,0,1,17.826,93.364ZM7.248,31.919a15.667,15.667,0,0,1-4.37-6.583C1.737,22.075.31,18.853.048,15.394-.458,8.735,3.11,4.453,8.44.941c3.318-2.186,5.257-.087,7.311,1.5a28.087,28.087,0,0,1,6.64,7.337,10.79,10.79,0,0,1,1.472,5.347c-.4,6-1.422,11.478-5.284,16.007a7.858,7.858,0,0,1-5.924,3.16A8.153,8.153,0,0,1,7.248,31.919Z"
          transform="translate(197.151 -21125)"
          :class="{ 'tooth--active': isActive(33)}"
        />
        <path
          data-name="14"
          class="tooth"
          d="M1.438,71.209A13.484,13.484,0,0,1,0,64.473c.59-3.953,1.447-8.234,4.172-11.823,3.938-5.19,9.916-5.308,14.149-.234A25.119,25.119,0,0,1,23.9,66.752c.33,4.025-2.213,6.631-5.42,8.632a12.341,12.341,0,0,1-6.544,1.9A12.151,12.151,0,0,1,1.438,71.209ZM15.224,48.056c-4.7-1.959-8.44-.979-11.687,3.061a.75.75,0,0,1-.67.325l-.32-.284c.456-10,1.152-19.983.992-30a125.77,125.77,0,0,0-.9-13.125c-.016-.136-.039-.272-.053-.408C2.227,4.19,4.113.961,6.973.109,9.021-.5,12.1,1.5,13.388,4.514c1.883,4.411,2.756,9.116,3.805,13.772A122.654,122.654,0,0,1,19.358,32.33a126.5,126.5,0,0,1,.92,19.805C18.512,50.486,17.164,48.866,15.224,48.056ZM2.543,52.05c-.077-.069,0-.314,0-.479l.319.284c-.032.13-.092.258-.191.258A.208.208,0,0,1,2.543,52.05Z"
          transform="translate(0.856 -21255)"
          :class="{ 'tooth--active': isActive(14)}"
        />
        <path
          data-name="45"
          class="tooth"
          d="M8.668,80.051c-.853-.077-.878-1.138-1.111-1.8A17.5,17.5,0,0,1,7,71.538a83.334,83.334,0,0,0-.686-16.618c-.75-4.837-2.083-9.6-2.6-14.454-.554-5.229-1.365-10.431-1.9-16.137,6.932,8.711,13.416,6.98,19.34.754-.5,2.993-.62,5.648-.95,8.248a71.878,71.878,0,0,0-.46,7.763,95.83,95.83,0,0,1-3.2,24.438c-1.319,4.675-2.866,9.357-6.007,13.28-.512.641-1.071,1.244-1.764,1.244Q8.724,80.056,8.668,80.051Zm3.441-51.385A9.311,9.311,0,0,1,3.439,23.5C.885,18.832-1.037,13.85.616,8.291A5.078,5.078,0,0,1,2.847,5.12c2.362-1.44,4.613-3.065,7-4.464,2.406-1.412,4.6-.285,6.685.94,1.458.857,2.834,1.853,4.265,2.758a5.559,5.559,0,0,1,2.869,4.684c.105,5.677-.8,11.142-4.534,15.682-1.883,2.285-4.242,3.951-6.8,3.951Q12.221,28.67,12.109,28.666Z"
          transform="translate(-28.22 -21125)"
          :class="{ 'tooth--active': isActive(45)}"
        />
        <path
          data-name="12"
          class="tooth"
          d="M16.633,80.42a44.644,44.644,0,0,0-4.66-.031c-.8-.068-1.611-.06-2.433-.051-2.074.022-4.2.043-6.217-1.169-2-1.2-3.283-2.479-3.317-5.045A75.448,75.448,0,0,1,1.379,58.658a11.764,11.764,0,0,1,5.658-7.814c2.486-1.522,5.237-.686,7.64,1.887a20.747,20.747,0,0,1,4.977,9.833,45.539,45.539,0,0,1,1.49,11.758c-.045,1.156.187,2.409-.358,3.421-.787,1.459-1.8,2.706-3.661,2.706A4.548,4.548,0,0,1,16.633,80.42ZM9.916,48.45c-3.235-.132-5.3,2.022-7.222,4.233,0-2.87-.175-5.655.032-8.41a166.2,166.2,0,0,0,.334-26.9A51.615,51.615,0,0,0,.876,5.728a9.373,9.373,0,0,1-.32-4.475c.2-1.221.68-1.667,1.911-.808A20.277,20.277,0,0,1,9.9,10.193c3.03,7.968,4.367,16.315,5.477,24.7.763,5.765,1.3,11.561,1.93,17.343.026.244,0,.494,0,1.2C15.106,50.9,13.135,48.581,9.916,48.45Z"
          transform="translate(64.956 -21258)"
          :class="{ 'tooth--active': isActive(12)}"
        />
        <path
          data-name="35"
          class="tooth"
          d="M13.6,79.265a17.327,17.327,0,0,1-2.616-3.856A77.73,77.73,0,0,1,4.833,55.426c-.547-2.986-.377-6.138-.6-9.213-.372-5.183-.623-10.377-.815-15.571-.065-1.75-.823-3.432-.53-5.3C8.215,30.988,14.8,33.309,22.052,24.2c-.676,4.99-1.154,9.413-1.891,13.791C18.207,49.6,15.909,61.169,16.842,73.049A15.536,15.536,0,0,1,15.857,79c-.28.884-.624,1.183-.973,1.183C14.418,80.186,13.942,79.652,13.6,79.265ZM2.12,20.618A33.4,33.4,0,0,1,.051,11.064a6.763,6.763,0,0,1,3.62-7.178C5.443,2.908,7.092,1.7,8.881.757a4.957,4.957,0,0,1,5.577.208,28.1,28.1,0,0,0,4.626,2.878c3.742,1.687,4.856,4.851,4.657,8.47a21.584,21.584,0,0,1-4.477,12.635c-1.8,2.242-4.075,3.8-7.249,3.675-.141.013-.283.019-.426.019C8.031,28.642,3.839,24.808,2.12,20.618Z"
          transform="translate(260.821 -21125)"
          :class="{ 'tooth--active': isActive(35)}"
        />
        <path
          data-name="32"
          class="tooth"
          d="M7.42,81.461a19.8,19.8,0,0,1-1.845-4.946C2.588,62.968,2.728,49.172,2.225,35.426c-.087-2.4-.148-4.8-.2-7.195-.006-.3-.147-.715.318-.85.283-.084.463.04.679.272,4.3,4.612,5.321,4.619,9.852.117.082-.082.237-.091.551-.2-.751,7.086-1.466,14.022-2.223,20.955-1.053,9.643-1.527,19.321-1.828,29.012-.036,1.164-.108,2.327-.143,3.492A1.25,1.25,0,0,1,8.4,82.3a.192.192,0,0,1-.043,0C8.09,82.3,7.6,81.814,7.42,81.461ZM4.631,27.48a18.4,18.4,0,0,1-2.615-5.3C-.19,15.578-.107,8.724.1,1.877.134.572.768-.071,2.18.007,3.888.1,5.606.03,7.32.028c1.508,0,3.017,0,4.525,0,2.306.008,3.374.811,3.714,3.155.587,4.034-.091,8.062-.331,12.083a56.741,56.741,0,0,1-.993,6.043,10.911,10.911,0,0,1-4.665,7.247,3.645,3.645,0,0,1-2.117.844C6.375,29.4,5.5,28.634,4.631,27.48Z"
          transform="translate(171.884 -21125)"
          :class="{ 'tooth--active': isActive(32)}"
        />
        <path
          data-name="22"
          class="tooth"
          d="M4.369,80.413A3.99,3.99,0,0,1,.092,76.471,47.275,47.275,0,0,1,2.1,60.5a17.969,17.969,0,0,1,3.816-7.069c3.976-4.634,7.542-4.275,11.433.333,2.044,2.42,2.552,5.341,2.918,8.315a84.686,84.686,0,0,1,.823,13.075,3.57,3.57,0,0,1-1.344,2.7,9.407,9.407,0,0,1-6.127,2.511q-2.261,0-4.523,0v.051c-.976,0-1.952,0-2.927,0Q5.269,80.42,4.369,80.413Zm-.2-32.389c1.035-8.969,2.02-17.942,3.874-26.8C9.189,15.745,10.7,10.421,13.651,5.585A20.139,20.139,0,0,1,18.679.476c1.175-.934,1.691-.4,1.909.814a13.41,13.41,0,0,1-.768,5.993c-2.24,8.08-2.332,16.347-2.061,24.627.225,6.895.653,13.784,1.017,21.21-2.318-2.7-4.4-4.809-7.68-4.631-3.167.17-5.066,2.462-7.223,4.951A32.835,32.835,0,0,1,4.166,48.024Z"
          transform="translate(170.224 -21258)"
          :class="{ 'tooth--active': isActive(22)}"
        />
        <path
          data-name="42"
          class="tooth"
          d="M7.257,82.4c-.654-.079-.677-.778-.728-1.319-.07-.75-.126-1.5-.141-2.255C6.153,67.667,5.319,56.551,4.2,45.439c-.6-5.909-1.553-11.785-1.817-18.091C4.29,29.32,6,31.236,8.558,31.11c2.489-.122,3.542-2.566,5.2-4.48-.171,11.706-.54,23-1.277,34.272A90.08,90.08,0,0,1,10.053,77.27a26.037,26.037,0,0,1-1.668,4.153c-.194.432-.537.986-1.043.986A.712.712,0,0,1,7.257,82.4ZM8.286,29.4c-2.55-.174-4.218-2.191-5.468-4.344C1.015,21.942.941,18.367.47,14.954A85.452,85.452,0,0,1,0,4.7C-.041,2.079,1-.4,4.67.052a32.42,32.42,0,0,0,3.9.025c.519,0,1.039.005,1.558.011C11.325.1,12.519.115,13.71.055a1.626,1.626,0,0,1,1.935,1.8c.221,7.938.327,15.866-3.114,23.316a2.327,2.327,0,0,1-.183.367c-1.071,1.529-1.738,3.868-3.856,3.868Q8.391,29.406,8.286,29.4Z"
          transform="translate(73.853 -21125)"
          :class="{ 'tooth--active': isActive(42)}"
        />
        <path
          data-name="34"
          class="tooth"
          d="M15.6,78.326C13,75.811,12,72.453,10.926,69.193c-1.28-3.89-2.2-7.9-3.362-11.826A109.628,109.628,0,0,1,5.409,46.451c-1.2-6.362-1.875-12.792-2.682-19.205-.136-1.078-.278-2.156-.418-3.233,2.566,2.264,5.049,4.4,8.665,4.516,3.587.112,6.425-1.368,9.011-4.447-.551,5.022-1.267,9.512-1.495,14.027-.523,10.406-.928,20.818.694,31.195.44,2.813.792,5.737-.462,8.5-.479,1.055-.9,1.557-1.437,1.557C16.836,79.361,16.309,79.006,15.6,78.326ZM3.383,21.935a25.571,25.571,0,0,1-3.1-8.12c-.765-3.479-.059-6.427,3-8.637,1.8-1.3,3.428-2.844,5.177-4.224a3.55,3.55,0,0,1,4.8-.037Q16.824,3.756,20.3,6.7a6.13,6.13,0,0,1,2.235,4.937c-.474,3.938-1.174,7.907-3.705,11.319a9.068,9.068,0,0,1-7.3,3.959A9.723,9.723,0,0,1,3.383,21.935Z"
          transform="translate(228.481 -21125.002)"
          :class="{ 'tooth--active': isActive(34)}"
        />
        <path
          data-name="15"
          class="tooth"
          d="M2.254,76.118A8.6,8.6,0,0,1,.023,69.327c.535-4.8.994-9.684,3.841-13.78,3.628-5.22,7.893-6.295,12.341-1.419,4.733,5.19,6.538,11.669,6.465,18.672-.029,2.726-2.317,3.7-4,4.993A11.142,11.142,0,0,1,11.9,79.812C8.291,79.812,4.511,78.5,2.254,76.118ZM3.568,53c.528-3.1.1-5.872.7-8.681a39.378,39.378,0,0,0,.69-7.564c.184-9.94.258-19.881-.085-29.822A42.528,42.528,0,0,1,5.083,1.8,1.764,1.764,0,0,1,6.211.043c.861-.235,1.1.538,1.521,1.068A21.3,21.3,0,0,1,11.106,8.7a145.221,145.221,0,0,1,3.4,14.521c1.183,6.449,2.2,12.926,2.855,19.457.358,3.6.818,7.184,1.276,11.149C10.918,47.3,8.465,49.046,3.568,53Z"
          transform="translate(-26.487 -21258)"
          :class="{ 'tooth--active': isActive(15)}"
        />
        <path
          data-name="25"
          class="tooth"
          d="M.206,68.406A47.265,47.265,0,0,1,3.99,57.252a12.014,12.014,0,0,1,6.387-5.9c2.293-.95,5.15.089,7.336,2.561,2.527,2.857,3.508,6.355,4.25,9.966a17.559,17.559,0,0,1,.657,5.3c.4,4.087-1.639,8.085-6.373,9.239-2.006.489-3.732,1.684-5.963,1.768-.122,0-.244.007-.365.007A9.94,9.94,0,0,1,.206,68.406ZM4.327,51.3c.635-5.085,1.05-10.194,1.613-15.281a84.8,84.8,0,0,1,1.485-9.558c.914-4,1.512-8.086,2.564-12.05,1.071-4.034,1.945-8.168,4-11.877.6-1.078,1.059-2.756,2.489-2.512s1.241,1.922,1.239,3.109C17.716,8.821,17.709,14.51,17.6,20.2A267.151,267.151,0,0,0,18.972,51.6c.031.315,0,.635,0,1.008-5.571-4.055-7.169-5.093-14.781.959C4.243,52.708,4.241,52,4.327,51.3Z"
          transform="translate(260.09 -21258)"
          :class="{ 'tooth--active': isActive(25)}"
        />
        <path
          data-name="41"
          class="tooth"
          d="M6.12,74.627a9.813,9.813,0,0,1-.552-3.415A210.508,210.508,0,0,0,4.425,46.567c-.723-6.513-.6-13.091-2-19.757,4.855,3.429,6.129,3.382,11.218-.75,0,1.6,0,3,0,4.393a257.018,257.018,0,0,1-1.889,30.771c-.521,4.262-1.077,8.551-2.959,12.512a3.893,3.893,0,0,1-.687,1.225c-.429.432-.79.724-1.11.724S6.389,75.385,6.12,74.627Zm.952-47.056c-2.018-.24-3.976-2.42-4.552-4.847A81.31,81.31,0,0,1,0,4.29C-.014,1.1.994.022,4.231.014c1.439,0,2.878,0,4.317,0V0c1.644,0,3.289,0,4.933,0A2.252,2.252,0,0,1,16.01,2.523a73.353,73.353,0,0,1-.848,11.662c-.491,3.437-1.014,6.859-2.574,10A6.046,6.046,0,0,1,7.528,27.6,3.836,3.836,0,0,1,7.072,27.571Z"
          transform="translate(102.385 -21125)"
          :class="{ 'tooth--active': isActive(41)}"
        />
        <path
          data-name="31"
          class="tooth"
          d="M8.959,75.653c-.8-.043-1.3-.972-1.695-1.834C5.495,69.966,4.89,65.838,4.372,61.711A267.8,267.8,0,0,1,2.411,26.186c5.56,4.039,6.4,4.092,10.9.8.318,1.251-.2,2.408-.307,3.593C11.729,44.3,10.375,58,10.487,71.8a4.389,4.389,0,0,1-.224,1.818c-.336.726-.174,2.038-1.229,2.038ZM3.623,24.531a21.516,21.516,0,0,1-2.477-7.961A85.115,85.115,0,0,1,.009,2.529C.043.7.923.086,2.48.032,4.191-.026,5.908.019,7.623.019c1.645,0,3.293-.049,4.937.012,2.271.084,3.476,1.239,3.5,3.37a81.482,81.482,0,0,1-2.706,20.181,5.12,5.12,0,0,1-4.419,3.974,5.3,5.3,0,0,1-.621.037A5.49,5.49,0,0,1,3.623,24.531Z"
          transform="translate(142.577 -21125)"
          :class="{ 'tooth--active': isActive(31)}"
        />
        <path
          data-name="37"
          class="tooth"
          d="M19.928,70.4c-3.761-1.227-5.862-4.253-7.457-7.516-2.421-4.954-3.762-10.3-5.193-15.627-1.36-5.058-2.4-10.182-3.331-15.3-.63-3.461-1.733-6.913-1.485-10.639,1.467.007,2.435,1.259,3.859,1.47a22.434,22.434,0,0,0,8.936-.511,4.366,4.366,0,0,1,2.786.37c5.834,2.322,11.264,2.037,16-2.55l.388.333a143.709,143.709,0,0,0-2.227,18.116c-.249,7.725,1.94,14.908,5.374,21.775a10.741,10.741,0,0,1,1.153,7.537c-.513,2.039-1.147,2.224-2.763.894-3.181-2.621-5.417-6-7.542-9.465C25.5,54.5,23.2,49.36,20.026,44.711a2.046,2.046,0,0,0-2.473-1.1c-1.269.322-1.164,1.386-1.033,2.34A92.713,92.713,0,0,0,20.445,62.43c.674,2.051,1.879,3.954,1.773,6.245-.069,1.477-.192,2.042-.9,2.042A5.042,5.042,0,0,1,19.928,70.4Zm1.409-48.488c-3.436-1.42-6.709-1.275-10.158-.653-5.548,1-11.192-2.808-11.074-8.8a.874.874,0,0,1,.333.087c-.7-.682-.35-1.639-.35-2.634V7.048q.046-.859.093-1.716C.934,3.4,2.3.24,4.709.056A19.1,19.1,0,0,1,7.893.077a11.65,11.65,0,0,1,3.134,1.214A17.61,17.61,0,0,1,14.1,3.284c2.012,1.526,4.021,1.866,6.156.349a17.528,17.528,0,0,1,5.61-2.2l.9-.074C30.272.609,33.5,2.8,34.875,6.851c2.21,6.536.011,11.942-6.132,14.849a9.259,9.259,0,0,1-3.955.88A9.069,9.069,0,0,1,21.337,21.909ZM.727,13.262a.5.5,0,0,0,.084-.171A.5.5,0,0,1,.727,13.262Zm.006-.489Z"
          transform="translate(342.025 -21125)"
          :class="{ 'tooth--active': isActive(37)}"
        />
        <path
          data-name="44"
          class="tooth"
          d="M3.858,77.965a6.738,6.738,0,0,1-.7-4.378q.034-2.707.068-5.413a.613.613,0,0,1,.582-.673q.306-4.161.612-8.323V43.12c-.113-3.979-.664-7.917-1.1-11.866l-.127-1.721c.11-1.341-1.088-3.2-.108-3.847,1.127-.749,2.138,1.185,3.288,1.806a11.725,11.725,0,0,0,6.139,1.05,1.541,1.541,0,0,0,.292.4,1.541,1.541,0,0,1-.292-.4L13.4,28.4a12.139,12.139,0,0,0,5.824-3.8.587.587,0,0,1,.833-.557c.427.182.258.507-.1.7.065,2.869-.715,5.65-.886,8.5-.032.7.291,1.466-.4,2.034q-.428,3.511-.855,7.024c.04.454.157.931-.354,1.228.121,1.986-.786,3.817-.877,5.771,0,.447.169.946-.374,1.228.078,1.579-.708,3-.841,4.537.02.458.121.943-.4,1.23-.321,3.134-1.435,6.084-2.142,9.128a37.187,37.187,0,0,1-5.29,12.577c-.527.78-.95,1.5-1.968,1.51H5.55C4.546,79.505,4.267,78.659,3.858,77.965Zm9.181-48.943a.181.181,0,0,1-.043.005A.181.181,0,0,0,13.039,29.022ZM13.2,28.9a.7.7,0,0,0,.044-.07A.7.7,0,0,1,13.2,28.9ZM3.178,22.39A25.128,25.128,0,0,1,0,11.71,6.021,6.021,0,0,1,1.687,7.331,75.472,75.472,0,0,1,9.462.862a3.022,3.022,0,0,1,4.116-.1C15.863,2.74,18.43,4.359,20.6,6.5a5.951,5.951,0,0,1,1.92,4.568C22.3,15.932,20.9,20.486,17.534,24.1A8.782,8.782,0,0,1,11.063,27,9.3,9.3,0,0,1,3.178,22.39Z"
          transform="translate(5.13 -21125)"
          :class="{ 'tooth--active': isActive(44)}"
        />
        <path
          data-name="24"
          class="tooth"
          d="M3.245,75.183c-3.1-2.5-3.776-5.75-2.879-9.676,1.042-4.56,2.487-8.83,5.818-12.217,4.468-4.543,9.7-4.242,13.516.792,2.767,3.652,3.621,8,4.153,11.841-.105,6.456-5.444,12.477-11.257,13.091a8.828,8.828,0,0,1-.927.049C8.363,79.064,5.715,77.176,3.245,75.183Zm.185-20.9c-.009.079-.017.121-.023.121C3.414,54.359,3.422,54.319,3.43,54.278Zm18.236-.015h0v0ZM4.183,39.9c.268-5,.5-10.115,1.15-15.125a58.251,58.251,0,0,1,4.1-14.508l2.227-5.54c.646-1.606,1.413-3.348,2.963-4.119A6.488,6.488,0,0,1,16.7.081,3.891,3.891,0,0,1,19.751.59a3.822,3.822,0,0,1,.992,1.744,28.283,28.283,0,0,1,1.49,9.337c-.147,14.246-.31,28.348-.567,42.593a22.232,22.232,0,0,0-1.644-2.917,5.959,5.959,0,0,0-1.625-1.94c-1.4-.922-3.641-.827-5.24-.729-3.5.216-8.9,1.562-9.727,5.6C3.585,52.945,4.122,41.042,4.183,39.9Z"
          transform="translate(231.594 -21257)"
          :class="{ 'tooth--active': isActive(24)}"
        />
        <line
          data-name="Line 2"
          class="line"
          y2="233.943"
          transform="translate(129.028 -21268.568)"
        />
        <line
          data-name="Line 3"
          class="line"
          y2="612.594"
          transform="translate(433.597 -21151.596) rotate(90)"
        />
        <text class="nums" transform="translate(53 -21134)">
          <tspan x="-8.372" y="0">43</tspan>
        </text>
        <text class="nums" transform="translate(48.501 -21161)">
          <tspan x="-7.028" y="0">13</tspan>
        </text>
        <text class="nums" transform="translate(78.821 -21161)">
          <tspan x="-7" y="0">12</tspan>
        </text>
        <text class="nums" transform="translate(106 -21161)">
          <tspan x="-6.076" y="0">11</tspan>
        </text>
        <text class="nums" transform="translate(150 -21161)">
          <tspan x="-6.86" y="0">21</tspan>
        </text>
        <text class="nums" transform="translate(-148.573 -21161)">
          <tspan x="-7.147" y="0">18</tspan>
        </text>
        <text class="nums" transform="translate(-106.115 -21161)">
          <tspan x="-6.937" y="0">17</tspan>
        </text>
        <text class="nums" transform="translate(-55.594 -21161)">
          <tspan x="-7.126" y="0">16</tspan>
        </text>
        <text class="nums" transform="translate(11.107 -21161)">
          <tspan x="-7.42" y="0">14</tspan>
        </text>
        <text class="nums" transform="translate(-152 -21134)">
          <tspan x="-8.491" y="0">48</tspan>
        </text>
        <text class="nums" transform="translate(-104 -21134)">
          <tspan x="-8.281" y="0">47</tspan>
        </text>
        <text class="nums" transform="translate(-56 -21134)">
          <tspan x="-8.435" y="0">46</tspan>
        </text>
        <text class="nums" transform="translate(-16 -21134)">
          <tspan x="-8.484" y="0">45</tspan>
        </text>
        <text class="nums" transform="translate(18 -21134)">
          <tspan x="-8.764" y="0">44</tspan>
        </text>
        <text class="nums" transform="translate(83 -21134)">
          <tspan x="-8.274" y="0">42</tspan>
        </text>
        <text class="nums" transform="translate(109 -21134)">
          <tspan x="-7.35" y="0">41</tspan>
        </text>
        <text class="nums" transform="translate(150 -21134)">
          <tspan x="-6.958" y="0">31</tspan>
        </text>
        <text class="nums" transform="translate(180.894 -21161)">
          <tspan x="-7.784" y="0">22</tspan>
        </text>
        <text class="nums" transform="translate(181 -21134)">
          <tspan x="-7.882" y="0">32</tspan>
        </text>
        <text class="nums" transform="translate(208.903 -21161)">
          <tspan x="-7.882" y="0">23</tspan>
        </text>
        <text class="nums" transform="translate(211 -21134)">
          <tspan x="-7.98" y="0">33</tspan>
        </text>
        <text class="nums" transform="translate(242.954 -21161)">
          <tspan x="-8.274" y="0">24</tspan>
        </text>
        <text class="nums" transform="translate(-17.191 -21161)">
          <tspan x="-7.21" y="0">15</tspan>
        </text>
        <text class="nums" transform="translate(242 -21134)">
          <tspan x="-8.372" y="0">34</tspan>
        </text>
        <text class="nums" transform="translate(273 -21161)">
          <tspan x="-7.994" y="0">25</tspan>
        </text>
        <text class="nums" transform="translate(274 -21134)">
          <tspan x="-8.092" y="0">35</tspan>
        </text>
        <text class="nums" transform="translate(309.245 -21161)">
          <tspan x="-7.945" y="0">26</tspan>
        </text>
        <text class="nums" transform="translate(313 -21134)">
          <tspan x="-8.043" y="0">36</tspan>
        </text>
        <text class="nums" transform="translate(356.745 -21161)">
          <tspan x="-7.791" y="0">27</tspan>
        </text>
        <text class="nums" transform="translate(360.745 -21134)">
          <tspan x="-7.889" y="0">37</tspan>
        </text>
        <text class="nums" transform="translate(403.234 -21161)">
          <tspan x="-8.001" y="0">28</tspan>
        </text>
        <text class="nums" transform="translate(406 -21134)">
          <tspan x="-8.099" y="0">38</tspan>
        </text>
      </g>
    </svg>
  </div>
</template>

<script>
export default {
  props: ['value', 'disabled'],
  methods: {
    clicked(e) {
      if (!this.disabled) {
        const teeth = this.value.slice();
        const n = Number.parseInt(e.target.getAttribute('data-name'));
        if (n) {
          const i = teeth.indexOf(n);
          if (i > -1) {
            teeth.splice(i, 1);
          } else {
            teeth.push(n);
          }
          this.$emit('input', teeth);
          this.$emit(teeth.length ? 'activated' : 'deactivated');
        }
      }
    },
    isActive(n) {
      return this.value.indexOf(n) > -1;
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/scss/variables.scss';
@import '@/scss/mixins.scss';
.teeth {
  display: flex;
  flex-wrap: wrap;
}
svg {
  height: auto;
  width: 100%;
}
.tooth {
  fill: #cfcfcf;
  stroke: rgba(0, 0, 0, 0);
  stroke-miterlimit: 10;
  // @include transition(all $transition $cubic);
  &.tooth--active {
    fill: #0077c7;
  }
}

.line {
  fill: none;
  opacity: 0.5;
  stroke: #416184;
}

.nums {
  font-family: GoogleSans-Bold, Google Sans;
  font-size: 14px;
  font-weight: 700;
  @include noselect();
}

.teeth:not(.teeth--disabled) {
  .tooth {
    cursor: pointer;
    &:not(.tooth--active):hover {
      fill: #4da1ff80;
    }
  }
}

@media screen and (max-width: $s) {
  .teeth {
    overflow: auto;
  }
  svg {
    min-width: 600px;
  }
}

@media print {
  .teeth {
    overflow: initial;
  }
  svg {
    min-width: initial;
  }
}
</style>